
import { defineComponent } from 'vue'
import Dropdown1 from '@/components/dropdown/Dropdown1.vue'

export default defineComponent({
  name: 'kt-widget-5',
  props: {
    widgetClasses: String
  },
  components: {
    Dropdown1
  }
})

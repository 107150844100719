import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row gy-5 g-xl-10" }
const _hoisted_2 = { class: "col-xl-4" }
const _hoisted_3 = { class: "col-xl-4" }
const _hoisted_4 = { class: "col-xl-4" }
const _hoisted_5 = { class: "row gy-5 g-xl-10" }
const _hoisted_6 = { class: "col-xxl-6" }
const _hoisted_7 = { class: "col-xxl-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MixedWidget12 = _resolveComponent("MixedWidget12")!
  const _component_MixedWidget13 = _resolveComponent("MixedWidget13")!
  const _component_MixedWidget14 = _resolveComponent("MixedWidget14")!
  const _component_TablesWidget9 = _resolveComponent("TablesWidget9")!
  const _component_ListsWidget5 = _resolveComponent("ListsWidget5")!
  const _component_ListsWidget4 = _resolveComponent("ListsWidget4")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_MixedWidget12, {
          "widget-classes": "card-xl-stretch mb-xl-10",
          "widget-color": "#F7D9E3",
          "chart-height": "100"
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_MixedWidget13, {
          "widget-classes": "card-xxl-stretch mb-xl-10",
          "widget-color": "#CBF0F4",
          "chart-height": "100"
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_MixedWidget14, {
          "widget-classes": "card-xxl-stretch mb-5 mb-xl-10",
          "widget-color": "#CBD4F4"
        })
      ])
    ]),
    _createVNode(_component_TablesWidget9, { "widget-classes": "mb-5 mb-xl-10" }),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_ListsWidget5, { "widget-classes": "card-xl-stretch mb-xl-10" })
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_ListsWidget4, { "widget-classes": "card-xl-stretch mb-5 mb-xl-10" })
      ])
    ])
  ], 64))
}

import { defineComponent, onMounted, onUnmounted } from 'vue'
import LayoutService from '@/core/services/LayoutService'
import MixedWidget12 from '@/components/widgets/mixed/Widget12.vue'
import MixedWidget13 from '@/components/widgets/mixed/Widget13.vue'
import MixedWidget14 from '@/components/widgets/mixed/Widget14.vue'
import TablesWidget9 from '@/components/widgets/tables/Widget9.vue'
import ListsWidget5 from '@/components/widgets/lists/Widget5.vue'
import ListsWidget4 from '@/components/widgets/lists/Widget4.vue'
import { setCurrentPageTitle } from '@/core/helpers/breadcrumb'

export default defineComponent({
  name: 'dashboard',
  components: {
    MixedWidget12,
    MixedWidget13,
    MixedWidget14,
    TablesWidget9,
    ListsWidget5,
    ListsWidget4
  },
  setup () {
    onMounted(() => {
      setCurrentPageTitle('Dashboard')
      // if (!localStorage.getItem('config')) {
      //   LayoutService.enableSidebar()
      // }
    })

    onUnmounted(() => {
      if (!localStorage.getItem('config')) {
        LayoutService.disableSidebar()
      }
    })
  }
})

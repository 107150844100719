
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'widget-14',
  props: {
    widgetClasses: String,
    widgetColor: String
  }
})

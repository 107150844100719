
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'kt-widget-9',
  components: {},
  props: {
    widgetClasses: String
  },
  setup () {
    const checkedRows = ref([])

    const list = [
      {
        image: 'media/avatars/300-14.jpg',
        name: 'Ana Simmons',
        skills: 'HTML, JS, ReactJS',
        companyName: 'Intertico',
        companySkills: 'Web, UI/UX Design',
        value: '50',
        color: 'primary'
      },
      {
        image: 'media/avatars/300-2.jpg',
        name: 'Jessie Clarcson',
        skills: 'C#, ASP.NET, MS SQL',
        companyName: 'Agoda',
        companySkills: 'Houses & Hotels',
        value: '70',
        color: 'danger'
      },
      {
        image: 'media/avatars/300-5.jpg',
        name: 'Lebron Wayde',
        skills: 'PHP, Laravel, VueJS',
        companyName: 'RoadGee',
        companySkills: 'Transportation',
        value: '60',
        color: 'success'
      },
      {
        image: 'media/avatars/300-20.jpg',
        name: 'Natali Goodwin',
        skills: 'Python, PostgreSQL, ReactJS',
        companyName: 'The Hill',
        companySkills: 'Insurance',
        value: '50',
        color: 'warning'
      },
      {
        image: 'media/avatars/300-23.jpg',
        name: 'Kevin Leonard',
        skills: 'HTML, JS, ReactJS',
        companyName: 'RoadGee',
        companySkills: 'Art Director',
        value: '90',
        color: 'info'
      }
    ]

    return {
      list,
      checkedRows
    }
  }
})
